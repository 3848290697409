<template>
  <div class="mt-6">
    <div v-for="(item, index) in values" :key="index">
      <hr class="">
      <div class="columns">
        <h4 class="column has text-grey-light has-text-weight-semibold">{{ item.label }}</h4>
        <component
          :is="item.count ? 'a' : 'span'"
          class="column is-narrow"
          :class="{
            'text-on-surface-secondary': !item.count,
            'text-link hover:underline hover:text-link': item.count
          }"
          @click="item.count ? $emit('show-stock', item.identifier) : null"
        >
          {{ item.count }} {{ item.count === 1 ? 'Vehicle' : 'Vehicles' }} ({{ toFixed(item) }}%)
        </component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StockviewPercentageBreakdown',
  props: {
    breakdown: {
      type: Array,
      required: true
    }
  },
  computed: {
    values() {
      return this.breakdown.slice(0, 3)
    },
    unavailable() {
      return this.breakdown[3]
    }
  },
  methods: {
    toFixed({ percent }) {
      return Number.isInteger(percent) ? percent : percent.toFixed(2)
    },
    count({ count }) {
      return count === 1 ? '1 vehicle' : `${count} vehicles`
    },
  }
}
</script>

<style lang="sass" scoped>
  .column
    padding: 0.25rem 0.75rem
</style>